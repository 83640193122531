import React from "react"
import useOptions from "../../hooks/useOptions"
import DefaultButton from "../buttons/DefaultButton"
import Image from "../Image"
import DefaultPattern from "../patterns/DefaultPattern"
import SectionSubheading from "../subheadings/SectionSubheading"

function FooterNewsletter() {
  const {
    newsletter: { subheading, heading, image },
  } = useOptions()

  return (
    <section className="relative bg-black ">
      <DefaultPattern opacity={0.1} />
      <div className="relative flex items-center justify-center ">
        <div className="z-20 flex flex-col items-center px-6 py-20 text-center text-white justify-stretch md:py-40">
          <SectionSubheading subheading={subheading} />
          <h2 className="mt-6 u-h2 w-full md:max-w-[51.313rem]">{heading}</h2>

          <form className="flex flex-col items-center justify-center md:items-stretch w-full mt-12 md:flex-row max-w-[36.375rem]">
            <input
              type="email"
              placeholder="Email Address"
              className="w-full px-10 py-5 bg-transparent border border-white rounded-full outline-none flex-grow-1 border-opacity-30 md:mr-4"
            />
            <DefaultButton
              white
              containerClass="w-full md:w-auto"
              motionDivClass="h-full"
              className="justify-center h-full px-12 py-4 mt-4 text-black md:mt-0"
              title={`Subscribe`}
            />
          </form>
        </div>
        <div className="absolute z-0 flex justify-center w-full h-full text-center transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ">
          <Image image={image} objectFit="cover" />
        </div>
      </div>
    </section>
  )
}

export default FooterNewsletter
