import React, { useState } from "react"
import SubnavItem from "./SubnavItem"
import { motion, AnimatePresence } from "framer-motion"
import moment from "moment"
import { Link } from "gatsby"
import useBlogPosts from "../../hooks/useBlogPosts"

function Subnav({
  setShowSubnav,
  subnavItems,
  isExplore,
  showSubnav,
  isCommercial,
}) {
  const { recentPost } = useBlogPosts()

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="absolute top-0 left-0 z-40 lg:flex items-center justify-center w-full pt-40 pb-[4.625rem] bg-black hidden "
    >
      <div className="max-w-[1316px] w-full  t border-opacity-10 flex px-6 subnavPadding ">
        <div className="flex flex-wrap w-full md:-ml-12 xl:-ml-16">
          {isCommercial &&
            subnavItems?.nodes?.map((subItem, i) => (
              <div key={subItem?.id} className="w-1/4 pl-12 xl:pl-16">
                <AnimatePresence>
                  <SubnavItem
                    className={
                      isCommercial &&
                      subItem?.path === "#" &&
                      "min-h-[170px]  pointer-events-none"
                    }
                    {...subItem}
                    i={i}
                  />
                </AnimatePresence>
                {subItem?.childItems?.nodes?.length > 0 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.7, delay: i / 12 }}
                    className="border-t border-dashed border-beige border-opacity-20 mt-[29px]"
                  ></motion.div>
                )}

                <AnimatePresence>
                  {subItem?.childItems?.nodes?.map((child, index) => (
                    <ChildItem key={`child${index}`} {...child} i={i} />
                  ))}
                </AnimatePresence>
              </div>
            ))}

          {!isCommercial &&
            subnavItems?.nodes?.map((subItem, i, arr) => (
              <div
                key={`subnavItem${subItem?.id}`}
                className={`pl-12  xl:pl-16 ${arr.length === 2 && "w-1/3"} ${
                  arr.length === 3 && "w-1/3"
                } ${arr.length === 4 && "w-1/4"}  ${
                  arr.length >= 5 && "w-1/3 "
                } ${arr.length === 8 && "w-1/4"} ${
                  arr.length === 8 && i < arr.length - 4 && "mb-12"
                } ${arr.length >= 5 && i < arr.length - 3 && "mb-12"} `}
              >
                <AnimatePresence>
                  <SubnavItem {...subItem} i={i} />
                </AnimatePresence>
              </div>
            ))}
          {isExplore && showSubnav && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.4,
                delay: (2 + 1) / 12,
                ease: "easeIn",
              }}
              key={`menuPost`}
              className="w-1/3 pl-12 xl:pl-16 "
            >
              <Link to={recentPost?.nodes[0]?.uri}>
                <div className="bg-[#1C1C1C] pl-7 py-8 pr-5 ">
                  <div className="flex text-10 text-beige font-semibold uppercase opacity-70 tracking-[0.05em] leading-[1.109] mb-7">
                    <div className="mr-8">recent article</div>
                    <div>
                      {moment(recentPost?.nodes[0]?.date).format(
                        "MMMM DD YYYY"
                      )}
                    </div>
                  </div>

                  {/* TODO: Truncate */}
                  <h5 className="text-white overflow-ellipsis u-h5">
                    {recentPost?.nodes[0]?.title}
                  </h5>
                </div>
              </Link>
            </motion.div>
          )}
          {/* </AnimatePresence> */}
        </div>
      </div>
    </motion.div>
  )
}

export const ChildItem = ({ path, label, i }) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <motion.div
      className="text-white"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      key={`child${i}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, delay: (i + 1) / 12 }}
    >
      <Link to={path} className="flex items-center justify-between py-[22px]">
        <div>
          <div className="text-18 leading-[1.569] uppercase font-extrabold ">
            {label}
          </div>

          <motion.div
            initial={{ width: "0%" }}
            animate={{
              width: onHover ? "100%" : "0%",
              opacity: onHover ? 1 : 0,
            }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="w-full h-px bg-white"
          ></motion.div>
        </div>
        <motion.svg
          animate={{ x: onHover ? 5 : 0, scale: onHover ? 1.1 : 1 }}
          width="13"
          height="11"
          viewBox="0 0 13 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 4.39648H10.9673V5.93901H0V4.39648Z" fill="white" />
          <path
            d="M7.88363 10.337L6.89258 9.25659L10.6432 5.1685L6.89258 1.08041L7.88363 0L12.624 5.16852L7.88363 10.337Z"
            fill="white"
          />
        </motion.svg>
      </Link>
      <div className="border-t border-dashed border-beige border-opacity-20 "></div>
    </motion.div>
  )
}

export default Subnav
