import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion"

import Image from "~/components/Image"

import useOptions from "~/hooks/useOptions"
import HeaderNav from "./HeaderNav"
import Subnav from "./menus/Subnav"
import useHeader from "../hooks/useHeader"
import { NavToggle } from "./menus/NavToggle"
import MobileNav from "./menus/MobileNav"
import useDocumentScrollThrottled from "../hooks/useDocumentScrollThrottled"
import DefaultPattern from "./patterns/DefaultPattern"
import useMobileNav from "~/hooks/useMobileNav"

const Header = ({
  headerBg = "bg-light-grey",
  dark = false,
  noise,
  patternOpacity,
  showPattern,
}) => {
  const {
    header: { logo, whiteLogo, primaryCta },
  } = useOptions()

  const { headerNav } = useHeader()
  const { mobileNav } = useMobileNav()

  const [showSubnav, setShowSubnav] = useState(false)
  const [activeSubnav, setActiveSubnav] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isExplore, setIsExplore] = useState(false)
  const [isCommercial, setIsCommercial] = useState(false)
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const MINIMUM_SCROLL = 80
  const TIMEOUT_DELAY = 50

  useDocumentScrollThrottled(callbackData => {
    if (typeof window === "undefined") return

    const { previousScrollTop, currentScrollTop } = callbackData
    const isScrolledDown = previousScrollTop < currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    setIsScrolled(isMinimumScrolled)

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled)
    }, TIMEOUT_DELAY)
  })

  const subnavHandler = i => {
    setActiveSubnav(i)
    setShowSubnav(
      headerNav?.menuItems?.nodes[activeSubnav]?.childItems.nodes.length > 0
        ? true
        : false
    )
  }

  useEffect(() => {
    setIsExplore(
      headerNav?.menuItems?.nodes[activeSubnav]?.label === "Explore Future"
        ? true
        : false
    )
    setIsCommercial(
      headerNav?.menuItems?.nodes[activeSubnav]?.label === "Commercial"
        ? true
        : false
    )
  }, [activeSubnav])

  useEffect(() => {
    setActiveSubnav(activeSubnav)
    subnavHandler(activeSubnav)
  }, [activeSubnav])

  return (
    <header
      className={`${
        isScrolled ? "lg:py-[10px]" : "lg:py-[46px] "
      }transform fixed top-0 left-0 z-[100] px-5 md:px-12  ${
        headerBg === "bg-transparent" && isScrolled ? "bg-black " : headerBg
      } ${noise && "noisy"}  py-5 w-full mx-auto  ${
        headerBg !== "bg-transparent" && headerBg
      } ${
        dark && !showSubnav ? "text-white border-opacity-10" : "text-slate"
      } ${
        shouldHideHeader
          ? " lg:-translate-y-36 duration-700 ease-in-out delay-100"
          : "lg:translate-y-0 duration-500 ease-in-out "
      }  `}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="header"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: "easeInOut" }}
            className={`absolute lg:hidden h-[73px] z-50 left-0 block transition-opacity duration-[500] patternBorder-b
          bg-black w-full top-0`}
          ></motion.div>
        )}
      </AnimatePresence>
      {showPattern && <DefaultPattern opacity={patternOpacity} />}
      <div className="relative flex items-center justify-between mx-auto ">
        <Link
          onMouseEnter={() => setShowSubnav(false)}
          to="/"
          className="max-w-[106px] h-8 lg:h-full z-50"
        >
          <Image
            image={
              dark
                ? whiteLogo
                : showSubnav
                ? whiteLogo
                : isOpen
                ? whiteLogo
                : logo
            }
          />
        </Link>
        <div className="justify-end flex-1 hidden lg:flex">
          <HeaderNav
            dark={dark}
            headerNav={headerNav}
            activeNav={activeSubnav}
            subnavHandler={subnavHandler}
            setShowSubnav={setShowSubnav}
            showSubnav={showSubnav}
          />

          <Link
            onMouseEnter={() => setShowSubnav(false)}
            className={`ml-8 font-bold md:text-12 xl:text-14 uppercase text-center leading-[1.22] rounded-full py-3 px-7 z-50 transition-all duration-200 ease-in-out ${
              dark
                ? "bg-white bg-opacity-[0.06] hover:bg-opacity-100 hover:text-black"
                : "bg-[#231f20] bg-opacity-5 hover:bg-opacity-100 hover:text-white"
            }`}
            to={primaryCta?.url}
          >
            {primaryCta?.title}
          </Link>
        </div>

        <div
          className="z-50 flex items-center cursor-pointer lg:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`${
              isOpen ? "text-white" : dark ? "text-white" : "text-slate"
            } text-white text-14 font-semibold mr-1.5`}
          >
            Menu
          </div>
          <NavToggle dark={dark} isOpen={isOpen} />
        </div>
      </div>

      <AnimateSharedLayout layout>
        {showSubnav && (
          <div
            onMouseEnter={() => setShowSubnav(false)}
            style={{
              position: "absolute",
              top: 200,
              left: 0,
              right: 0,
              height: 5000,
            }}
          ></div>
        )}

        <AnimatePresence>
          {showSubnav && (
            <Subnav
              setShowSubnav={setShowSubnav}
              isExplore={isExplore}
              showSubnav={showSubnav}
              isCommercial={isCommercial}
              subnavItems={
                headerNav?.menuItems?.nodes[activeSubnav]?.childItems
              }
            />
          )}
        </AnimatePresence>
      </AnimateSharedLayout>

      <AnimateSharedLayout layout>
        <AnimatePresence>
          {isOpen && <MobileNav headerNav={mobileNav} setNavOpen={setIsOpen} />}
        </AnimatePresence>
      </AnimateSharedLayout>
    </header>
  )
}

export default Header
