import * as React from "react"
import Footer from "./Footer"
import FooterNewsletter from "./forms/FooterNewsletter"
import ScrollProgress from "~/components/animations/ScrollProgress"
import Header from "./Header"

const Layout = ({
  children,
  dark,
  headerBg,
  hideCTA = false,
  noise = true,
  showPattern = false,
  patternOpacity = 0.6,
  className = "",
}) => {
  return (
    <>
      <Header
        dark={dark}
        headerBg={headerBg}
        noise={noise}
        showPattern={showPattern}
        patternOpacity={patternOpacity}
      />

      <div className="fixed bottom-[7rem] right-[3rem] z-50 hidden md:block mix-blend-difference text-white">
        <ScrollProgress />
      </div>

      <main className={`${className}`}>{children}</main>

      <Footer />
    </>
  )
}

export default Layout
